import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2024 from '../ClassAssembly2024';
import Archive2022 from '../ClassAssembly2023/Archive2022';

const ClassAssembly9C2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/class-assembly/9C/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/class-assembly/9C/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/class-assembly/9C/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/class-assembly/9C/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/class-assembly/9C/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/class-assembly/9C/6.webp`;
    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },

        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        },
        
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1.5,
            cols: 2,
        },
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                EMPATHY 
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 9 C  Date: 16 NOVEMBER 2024
                                </Typography>
                                {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “We all have dreams. But in order to make dreams come into reality, it takes tremendous
    determination, dedication, self-discipline and effort.”

<br/>
        </Typography> */}

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                Students of 9C presented their assembly on the theme “EMPATHY”. The assembly commenced with an intriguing thought and an informative introduction that provided great context to the topic of the assembly- LACK OF EMPATHY.
                                    <br></br>
                                    The students depicted on how empathy not shown at the right time can change their life in many ways by citing one of the most reviled leader in history ADOLF HITLER. They presented his life highlighting his troubled childhood, rejections and constant lack of compassion at different stages of his life which contributed to his life of hatred and destruction and concluded that if he was shown more empathy at the right time of his life, probably the holocaust could have been avoided.
                                    <br></br>
                                    However, political ideologies and economic instability were the major causes of World War II and the holocaust, still critical thinking, responsibility and more empathy could have probably altered the course of history. In contrast to this grim show of apathy, a dance followed and represented contrasting view of peace and prosperity.
                                    <br></br>
                                    On 16/11/24, the assembly was marked by a noteworthy thought, an Informative quiz, a pledge taken on empathy by all the students and an enlightening conclusion to the events of the assembly.
                                    <br></br>
                                    Through this assembly, the students showcased that how empathy plays a critical role in shaping individual as well as our society. It is our concern for each other and our surroundings, that empathy is what will make our earth into a EUTOPIA. We must try a little harder to treat others as we would want to be treated.
                                    <br></br>
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><div>When we are empathetic, we “CONNECT”.</div>
                            <div>When we connect, we “UNDERSTAND” each other.</div>
                            <div>Understanding leads to “RESPECT” and ultimately</div>
                            <div>“LOVE”.</div>
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <ClassAssembly2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022 />
                        </Box>
                        {/* <SidebarNewsletter /> */}
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default ClassAssembly9C2024